<template>
  <NuxtLayout>
    <Body :class="isDarkBg ? 'dark' : ''" :data-dark-bg="isDarkBg">
      <NuxtPage />
      <AppSignIn v-if="signInVisible" v-bind:show-sign-up="enableSignUp"></AppSignIn>
      <AppSignUp v-if="signUpVisible"></AppSignUp>
      <AppDepositReport v-if="depositReportVisible"></AppDepositReport>
      <ClientOnly>
        <Toaster position="bottom-center" richColors closeButton offset="20px" />
      </ClientOnly>
    </Body>
  </NuxtLayout>
</template>

<script setup lang="ts">
import { Toaster } from 'vue-sonner';

const { frontendBaseUrl } = useRuntimeConfig().public;
const route = useRoute();

const { signInVisible, signUpVisible, enableSignUp, depositReportVisible } = useOverlay();
const { locale, t } = useI18n();

const fallbackColors = reactive({
  primary: '#ffffff',
  secondary: '#000000',
});
const sectionColors = useState('sectionColors', () => fallbackColors);
const isDarkBg = useState<boolean>('darkBg', () => false);

onMounted(() => {
  const { query } = useRoute();
  const { setSearchDate } = useSearch();
  if (query.start && typeof query.start === 'string') {
    setSearchDate(query.start, null);
  }
  if (query.end && typeof query.end === 'string') {
    setSearchDate(null, query.end);
  }
});

useHead({
  htmlAttrs: {
    lang: locale.value,
    style: computed(() => {
      return `--primary-section-color: ${sectionColors.value.primary};--secondary-section-color: ${sectionColors.value.secondary};`;
    }),
  },
  link: [
    {
      rel: 'canonical',
      href: () => `${frontendBaseUrl}${route.path}`,
    },
  ],
  meta: [{ name: 'description', content: t('seo-meta.fallback.description') }],
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} | ${t('brand.thg')}` : t('brand.thg');
  },
});

useSeoMeta({
  title: t('seo-meta.fallback.title'),
  ogTitle: t('seo-meta.fallback.title'),
  description: t('seo-meta.fallback.description'),
  ogDescription: t('seo-meta.fallback.description'),
  ogSiteName: t('brand.thg'),
  ogUrl: `${frontendBaseUrl}${route.path}`,
  ogType: 'website',
  ogLocale: locale.value ?? 'de',
  ogLocaleAlternate: ['en', 'de'].filter((lang) => lang !== locale.value),
  ogImage: {
    url: `${frontendBaseUrl}/img/og/thg-og-home-web.jpg`,
    type: 'image/jpeg',
    width: 2400,
    height: 1260,
    alt: 'The Hire Guys Website Screenshot',
  },
  twitterCard: 'summary_large_image',
  themeColor: () => sectionColors.value.primary ?? '#7939FF',
});
</script>
