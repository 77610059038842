import { default as aboutcggDDldG7iMeta } from "/vercel/path0/pages/about.vue?macro=true";
import { default as _91id_93gWrikVDPZwMeta } from "/vercel/path0/pages/account/bookings/[id].vue?macro=true";
import { default as editzapewJEQseMeta } from "/vercel/path0/pages/account/edit.vue?macro=true";
import { default as indexB6RoytqLJnMeta } from "/vercel/path0/pages/account/extend/[booking]-[car]/index.vue?macro=true";
import { default as failedNPRw9xQ6xgMeta } from "/vercel/path0/pages/account/extend/[booking]/failed.vue?macro=true";
import { default as indexELQAnQapyfMeta } from "/vercel/path0/pages/account/extend/[booking]/index.vue?macro=true";
import { default as successQApRitJA4nMeta } from "/vercel/path0/pages/account/extend/[booking]/success.vue?macro=true";
import { default as indexqOnLrLMUjLMeta } from "/vercel/path0/pages/account/index.vue?macro=true";
import { default as bookQp8t9GN4FzMeta } from "/vercel/path0/pages/cars/[id]/book.vue?macro=true";
import { default as failed5XAZx47k3GMeta } from "/vercel/path0/pages/cars/[id]/failed.vue?macro=true";
import { default as indexsfTJcoGq7lMeta } from "/vercel/path0/pages/cars/[id]/index.vue?macro=true";
import { default as successqYo69J2aoBMeta } from "/vercel/path0/pages/cars/[id]/success.vue?macro=true";
import { default as indexrmUHi3EZfNMeta } from "/vercel/path0/pages/cars/index.vue?macro=true";
import { default as contactJnsDpYVejzMeta } from "/vercel/path0/pages/contact.vue?macro=true";
import { default as faqM4LN7jxnMuMeta } from "/vercel/path0/pages/faq.vue?macro=true";
import { default as howGQ9IJ4kGjfMeta } from "/vercel/path0/pages/how.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as logoutBqDDZXgXsyMeta } from "/vercel/path0/pages/logout.vue?macro=true";
import { default as termsq5qlZHSSonMeta } from "/vercel/path0/pages/terms.vue?macro=true";
export default [
  {
    name: aboutcggDDldG7iMeta?.name ?? "about___en",
    path: aboutcggDDldG7iMeta?.path ?? "/en/about",
    meta: aboutcggDDldG7iMeta || {},
    alias: aboutcggDDldG7iMeta?.alias || [],
    redirect: aboutcggDDldG7iMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/about.vue").then(m => m.default || m)
  },
  {
    name: aboutcggDDldG7iMeta?.name ?? "about___de",
    path: aboutcggDDldG7iMeta?.path ?? "/de/ueber-uns",
    meta: aboutcggDDldG7iMeta || {},
    alias: aboutcggDDldG7iMeta?.alias || [],
    redirect: aboutcggDDldG7iMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/about.vue").then(m => m.default || m)
  },
  {
    name: _91id_93gWrikVDPZwMeta?.name ?? "account-bookings-id___en",
    path: _91id_93gWrikVDPZwMeta?.path ?? "/en/account/bookings/:id()",
    meta: _91id_93gWrikVDPZwMeta || {},
    alias: _91id_93gWrikVDPZwMeta?.alias || [],
    redirect: _91id_93gWrikVDPZwMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/account/bookings/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93gWrikVDPZwMeta?.name ?? "account-bookings-id___de",
    path: _91id_93gWrikVDPZwMeta?.path ?? "/de/account/bookings/:id()",
    meta: _91id_93gWrikVDPZwMeta || {},
    alias: _91id_93gWrikVDPZwMeta?.alias || [],
    redirect: _91id_93gWrikVDPZwMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/account/bookings/[id].vue").then(m => m.default || m)
  },
  {
    name: editzapewJEQseMeta?.name ?? "account-edit___en",
    path: editzapewJEQseMeta?.path ?? "/en/account/edit",
    meta: editzapewJEQseMeta || {},
    alias: editzapewJEQseMeta?.alias || [],
    redirect: editzapewJEQseMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/account/edit.vue").then(m => m.default || m)
  },
  {
    name: editzapewJEQseMeta?.name ?? "account-edit___de",
    path: editzapewJEQseMeta?.path ?? "/de/konto/bearbeiten",
    meta: editzapewJEQseMeta || {},
    alias: editzapewJEQseMeta?.alias || [],
    redirect: editzapewJEQseMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/account/edit.vue").then(m => m.default || m)
  },
  {
    name: indexB6RoytqLJnMeta?.name ?? "account-extend-booking-car___en",
    path: indexB6RoytqLJnMeta?.path ?? "/en/account/extend/:booking()-:car()",
    meta: indexB6RoytqLJnMeta || {},
    alias: indexB6RoytqLJnMeta?.alias || [],
    redirect: indexB6RoytqLJnMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/account/extend/[booking]-[car]/index.vue").then(m => m.default || m)
  },
  {
    name: indexB6RoytqLJnMeta?.name ?? "account-extend-booking-car___de",
    path: indexB6RoytqLJnMeta?.path ?? "/de/account/extend/:booking()-:car()",
    meta: indexB6RoytqLJnMeta || {},
    alias: indexB6RoytqLJnMeta?.alias || [],
    redirect: indexB6RoytqLJnMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/account/extend/[booking]-[car]/index.vue").then(m => m.default || m)
  },
  {
    name: failedNPRw9xQ6xgMeta?.name ?? "account-extend-booking-failed___en",
    path: failedNPRw9xQ6xgMeta?.path ?? "/en/account/extend/:booking/failed",
    meta: failedNPRw9xQ6xgMeta || {},
    alias: failedNPRw9xQ6xgMeta?.alias || [],
    redirect: failedNPRw9xQ6xgMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/account/extend/[booking]/failed.vue").then(m => m.default || m)
  },
  {
    name: failedNPRw9xQ6xgMeta?.name ?? "account-extend-booking-failed___de",
    path: failedNPRw9xQ6xgMeta?.path ?? "/de/konto/verlaengern/:booking/fehlgeschlagen",
    meta: failedNPRw9xQ6xgMeta || {},
    alias: failedNPRw9xQ6xgMeta?.alias || [],
    redirect: failedNPRw9xQ6xgMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/account/extend/[booking]/failed.vue").then(m => m.default || m)
  },
  {
    name: indexELQAnQapyfMeta?.name ?? "account-extend-booking___en",
    path: indexELQAnQapyfMeta?.path ?? "/en/account/extend/:booking",
    meta: indexELQAnQapyfMeta || {},
    alias: indexELQAnQapyfMeta?.alias || [],
    redirect: indexELQAnQapyfMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/account/extend/[booking]/index.vue").then(m => m.default || m)
  },
  {
    name: indexELQAnQapyfMeta?.name ?? "account-extend-booking___de",
    path: indexELQAnQapyfMeta?.path ?? "/de/konto/verlaengern/:booking",
    meta: indexELQAnQapyfMeta || {},
    alias: indexELQAnQapyfMeta?.alias || [],
    redirect: indexELQAnQapyfMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/account/extend/[booking]/index.vue").then(m => m.default || m)
  },
  {
    name: successQApRitJA4nMeta?.name ?? "account-extend-booking-success___en",
    path: successQApRitJA4nMeta?.path ?? "/en/account/extend/:booking/success",
    meta: successQApRitJA4nMeta || {},
    alias: successQApRitJA4nMeta?.alias || [],
    redirect: successQApRitJA4nMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/account/extend/[booking]/success.vue").then(m => m.default || m)
  },
  {
    name: successQApRitJA4nMeta?.name ?? "account-extend-booking-success___de",
    path: successQApRitJA4nMeta?.path ?? "/de/konto/verlaengern/:booking/erfolgreich",
    meta: successQApRitJA4nMeta || {},
    alias: successQApRitJA4nMeta?.alias || [],
    redirect: successQApRitJA4nMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/account/extend/[booking]/success.vue").then(m => m.default || m)
  },
  {
    name: indexqOnLrLMUjLMeta?.name ?? "account___en",
    path: indexqOnLrLMUjLMeta?.path ?? "/en/account",
    meta: indexqOnLrLMUjLMeta || {},
    alias: indexqOnLrLMUjLMeta?.alias || [],
    redirect: indexqOnLrLMUjLMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexqOnLrLMUjLMeta?.name ?? "account___de",
    path: indexqOnLrLMUjLMeta?.path ?? "/de/konto",
    meta: indexqOnLrLMUjLMeta || {},
    alias: indexqOnLrLMUjLMeta?.alias || [],
    redirect: indexqOnLrLMUjLMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: bookQp8t9GN4FzMeta?.name ?? "cars-id-book___en",
    path: bookQp8t9GN4FzMeta?.path ?? "/en/cars/:id/book",
    meta: bookQp8t9GN4FzMeta || {},
    alias: bookQp8t9GN4FzMeta?.alias || [],
    redirect: bookQp8t9GN4FzMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/cars/[id]/book.vue").then(m => m.default || m)
  },
  {
    name: bookQp8t9GN4FzMeta?.name ?? "cars-id-book___de",
    path: bookQp8t9GN4FzMeta?.path ?? "/de/autos/:id/buchen",
    meta: bookQp8t9GN4FzMeta || {},
    alias: bookQp8t9GN4FzMeta?.alias || [],
    redirect: bookQp8t9GN4FzMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/cars/[id]/book.vue").then(m => m.default || m)
  },
  {
    name: failed5XAZx47k3GMeta?.name ?? "cars-id-failed___en",
    path: failed5XAZx47k3GMeta?.path ?? "/en/cars/:id/failed",
    meta: failed5XAZx47k3GMeta || {},
    alias: failed5XAZx47k3GMeta?.alias || [],
    redirect: failed5XAZx47k3GMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/cars/[id]/failed.vue").then(m => m.default || m)
  },
  {
    name: failed5XAZx47k3GMeta?.name ?? "cars-id-failed___de",
    path: failed5XAZx47k3GMeta?.path ?? "/de/autos/:id/fehlgeschlagen",
    meta: failed5XAZx47k3GMeta || {},
    alias: failed5XAZx47k3GMeta?.alias || [],
    redirect: failed5XAZx47k3GMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/cars/[id]/failed.vue").then(m => m.default || m)
  },
  {
    name: indexsfTJcoGq7lMeta?.name ?? "cars-id___en",
    path: indexsfTJcoGq7lMeta?.path ?? "/en/cars/:id",
    meta: indexsfTJcoGq7lMeta || {},
    alias: indexsfTJcoGq7lMeta?.alias || [],
    redirect: indexsfTJcoGq7lMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/cars/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexsfTJcoGq7lMeta?.name ?? "cars-id___de",
    path: indexsfTJcoGq7lMeta?.path ?? "/de/autos/:id",
    meta: indexsfTJcoGq7lMeta || {},
    alias: indexsfTJcoGq7lMeta?.alias || [],
    redirect: indexsfTJcoGq7lMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/cars/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: successqYo69J2aoBMeta?.name ?? "cars-id-success___en",
    path: successqYo69J2aoBMeta?.path ?? "/en/cars/:id/success",
    meta: successqYo69J2aoBMeta || {},
    alias: successqYo69J2aoBMeta?.alias || [],
    redirect: successqYo69J2aoBMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/cars/[id]/success.vue").then(m => m.default || m)
  },
  {
    name: successqYo69J2aoBMeta?.name ?? "cars-id-success___de",
    path: successqYo69J2aoBMeta?.path ?? "/de/autos/:id/erfolgreich",
    meta: successqYo69J2aoBMeta || {},
    alias: successqYo69J2aoBMeta?.alias || [],
    redirect: successqYo69J2aoBMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/cars/[id]/success.vue").then(m => m.default || m)
  },
  {
    name: indexrmUHi3EZfNMeta?.name ?? "cars___en",
    path: indexrmUHi3EZfNMeta?.path ?? "/en/cars",
    meta: indexrmUHi3EZfNMeta || {},
    alias: indexrmUHi3EZfNMeta?.alias || [],
    redirect: indexrmUHi3EZfNMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/cars/index.vue").then(m => m.default || m)
  },
  {
    name: indexrmUHi3EZfNMeta?.name ?? "cars___de",
    path: indexrmUHi3EZfNMeta?.path ?? "/de/autos",
    meta: indexrmUHi3EZfNMeta || {},
    alias: indexrmUHi3EZfNMeta?.alias || [],
    redirect: indexrmUHi3EZfNMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/cars/index.vue").then(m => m.default || m)
  },
  {
    name: contactJnsDpYVejzMeta?.name ?? "contact___en",
    path: contactJnsDpYVejzMeta?.path ?? "/en/contact",
    meta: contactJnsDpYVejzMeta || {},
    alias: contactJnsDpYVejzMeta?.alias || [],
    redirect: contactJnsDpYVejzMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactJnsDpYVejzMeta?.name ?? "contact___de",
    path: contactJnsDpYVejzMeta?.path ?? "/de/kontakt",
    meta: contactJnsDpYVejzMeta || {},
    alias: contactJnsDpYVejzMeta?.alias || [],
    redirect: contactJnsDpYVejzMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: faqM4LN7jxnMuMeta?.name ?? "faq___en",
    path: faqM4LN7jxnMuMeta?.path ?? "/en/faq",
    meta: faqM4LN7jxnMuMeta || {},
    alias: faqM4LN7jxnMuMeta?.alias || [],
    redirect: faqM4LN7jxnMuMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: faqM4LN7jxnMuMeta?.name ?? "faq___de",
    path: faqM4LN7jxnMuMeta?.path ?? "/de/faq",
    meta: faqM4LN7jxnMuMeta || {},
    alias: faqM4LN7jxnMuMeta?.alias || [],
    redirect: faqM4LN7jxnMuMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: howGQ9IJ4kGjfMeta?.name ?? "how___en",
    path: howGQ9IJ4kGjfMeta?.path ?? "/en/how-it-works",
    meta: howGQ9IJ4kGjfMeta || {},
    alias: howGQ9IJ4kGjfMeta?.alias || [],
    redirect: howGQ9IJ4kGjfMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/how.vue").then(m => m.default || m)
  },
  {
    name: howGQ9IJ4kGjfMeta?.name ?? "how___de",
    path: howGQ9IJ4kGjfMeta?.path ?? "/de/so-funktionierts",
    meta: howGQ9IJ4kGjfMeta || {},
    alias: howGQ9IJ4kGjfMeta?.alias || [],
    redirect: howGQ9IJ4kGjfMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/how.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index___en",
    path: indexe9Brt5DfdhMeta?.path ?? "/en",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index___de",
    path: indexe9Brt5DfdhMeta?.path ?? "/de",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: logint0AWlhQgM0Meta?.name ?? "login___en",
    path: logint0AWlhQgM0Meta?.path ?? "/en/login",
    meta: logint0AWlhQgM0Meta || {},
    alias: logint0AWlhQgM0Meta?.alias || [],
    redirect: logint0AWlhQgM0Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logint0AWlhQgM0Meta?.name ?? "login___de",
    path: logint0AWlhQgM0Meta?.path ?? "/de/login",
    meta: logint0AWlhQgM0Meta || {},
    alias: logint0AWlhQgM0Meta?.alias || [],
    redirect: logint0AWlhQgM0Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logoutBqDDZXgXsyMeta?.name ?? "logout___en",
    path: logoutBqDDZXgXsyMeta?.path ?? "/en/logout",
    meta: logoutBqDDZXgXsyMeta || {},
    alias: logoutBqDDZXgXsyMeta?.alias || [],
    redirect: logoutBqDDZXgXsyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: logoutBqDDZXgXsyMeta?.name ?? "logout___de",
    path: logoutBqDDZXgXsyMeta?.path ?? "/de/logout",
    meta: logoutBqDDZXgXsyMeta || {},
    alias: logoutBqDDZXgXsyMeta?.alias || [],
    redirect: logoutBqDDZXgXsyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: termsq5qlZHSSonMeta?.name ?? "terms___en",
    path: termsq5qlZHSSonMeta?.path ?? "/en/terms",
    meta: termsq5qlZHSSonMeta || {},
    alias: termsq5qlZHSSonMeta?.alias || [],
    redirect: termsq5qlZHSSonMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: termsq5qlZHSSonMeta?.name ?? "terms___de",
    path: termsq5qlZHSSonMeta?.path ?? "/de/agb",
    meta: termsq5qlZHSSonMeta || {},
    alias: termsq5qlZHSSonMeta?.alias || [],
    redirect: termsq5qlZHSSonMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/terms.vue").then(m => m.default || m)
  }
]